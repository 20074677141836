import { AnalyticsMetrics } from '@interface/client';

export const getAnalyticsMetrics = (
  analyticsMetrics: AnalyticsMetrics
): AnalyticsMetrics => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const deviceStr = params.get('device');
  const treatmentText = params.get('treatmentText');
  const loc_physical_ms = params.get('loc_physical_ms');
  const utm_adgroup = params.get('utm_adgroup');
  const utm_campaign = params.get('utm_campaign');
  const utm_medium = params.get('utm_medium');
  const utm_source = params.get('utm_source');
  const utm_term = params.get('utm_term');
  const utm_content = params.get('utm_content');
  if (deviceStr) {
    let device = 0;
    switch (deviceStr) {
      case 'm':
        device = 3;
        break;
      case 't':
        device = 2;
        break;
      case 'd':
        device = 1;
        break;
      default:
        device = 0;
        break;
    }
    analyticsMetrics.device = device;
  }
  if (loc_physical_ms) analyticsMetrics.locPhysicalMs = loc_physical_ms;
  if (utm_adgroup) analyticsMetrics.utmAdgroup = utm_adgroup;
  if (utm_campaign) analyticsMetrics.utmCampaign = utm_campaign;
  if (utm_medium) analyticsMetrics.utmMedium = utm_medium;
  if (utm_source) analyticsMetrics.utmSource = utm_source;
  if (utm_term) analyticsMetrics.utmTerm = utm_term;
  if (utm_content) analyticsMetrics.utmContent = utm_content;
  if (treatmentText) analyticsMetrics.treatmentText = treatmentText;
  return analyticsMetrics;
};
