'use client';

import { useEffect } from 'react';
import { getAnalyticsMetrics } from '@utils/analyticsUtils';
import { useSessionStore } from 'app/stores/globalStore';

export default function AnalyticsMetrics() {
  const { analyticsMetrics, setAnalyticsMetrics } = useSessionStore(
    state => state
  );
  useEffect(() => {
    setAnalyticsMetrics(getAnalyticsMetrics(analyticsMetrics));
  }, []);
  return <></>;
}
